<template>
	<div class="page-content" @click="panDunIs($event)">
		<div v-if="roomStep==0">
			<div class="next">
				<el-button v-show="!editing&&themeSearch.page>1" size="small" icon="el-icon-arrow-left" circle @click="nextTo(-1)"></el-button>
			</div>
			<el-radio-group class="mlr-20" v-model="searchForm.themes_is" @change="handleClick">
			    <el-radio-button v-for="item in allThemeObj[themeSearch.page]" :key="item.themes_id" :label="item.themes_id" :name="item.themes_title">
					<div class="ellipsis1 w-90">
						{{item.themes_title}}
					</div>
				</el-radio-button>
			</el-radio-group>
			<div class="next">
				<el-button v-show="!editing&&allThemeObj[themeSearch.page]&&((allThemeObj[themeSearch.page].length==themeSearch.limit)||allThemeObj[themeSearch.page].length==7)" size="small" icon="el-icon-arrow-right" circle @click="nextTo(1)"></el-button>
			</div>
			<!-- <el-tabs v-model="searchForm.themes_is" type="card" @tab-click="handleClick">
			    <el-tab-pane></el-tab-pane>
			</el-tabs> -->
			<div class="park-form" :key="0">
				<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
					<el-form-item label="房间名称" prop="name">
						<el-input v-model="searchForm.name" style="width: 220px;" clearable placeholder="请填房间号码"></el-input>
					</el-form-item>
					<!-- <el-form-item label="主题" prop="themes_is">
						<el-select v-model="searchForm.themes_is" clearable placeholder="请选择主题">
							<el-option v-for="item in allThemeList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item> -->
			
					<!-- <el-form-item label="创建日期" prop="date">
						<el-date-picker v-model="searchForm.date" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
							placeholder="选择日期">
						</el-date-picker>
					</el-form-item> -->
					<el-form-item label="游玩日期" prop="days">
						<el-date-picker v-model="searchForm.days" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
							placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="开放状态" prop="is_open">
						<el-select v-model="searchForm.is_open" clearable placeholder="请选择开放状态">
							<el-option label="开放" value="1"></el-option>
							<el-option label="关闭" value="2"></el-option>
						</el-select>
					</el-form-item>
					<!-- status  1  未开始  2  已开始  3  已结束 -->
					<el-form-item label="开放状态" prop="status">
						<el-select v-model="searchForm.status" clearable placeholder="请选择游玩状态">
							<el-option label="未开始" value="1"></el-option>
							<el-option label="已开始" value="2"></el-option>
							<el-option label="已结束" value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="margin-top: auto;margin-right: auto;">
						<div class="fill-btn" @click="roomList">查询</div>
					</el-form-item>
					<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;" v-if="user_type==2">
						<div class="fill-btn" v-if="userInfo.id" @click="handShowAdd">新增</div>
					</el-form-item>
				</el-form>
			</div>
			<div class="table-content">
				<div class="table-top">
					<div class="table-top-text">
						<!--已选择{{selectData.length}}项-->
					</div>
				</div>
				<el-table :data="tableData" :key="0" stripe v-loading="loading" @selection-change="handleSelectionChange"
					cell-class-name="text-center" header-cell-class-name="header-row-class-name">
					<!-- <el-table-column prop="id" label="ID" width="60px"></el-table-column> -->
					<el-table-column prop="number" label="房间编号" min-width="80px"></el-table-column>
					<!-- <el-table-column prop="themes_title" label="房间主题" min-width="120px"></el-table-column> -->
					<el-table-column prop="num" label="可容纳人数" width="140">
						<template slot-scope="{row}">
							<el-input-number id="noClick" size="mini" v-model="row.num" @change="sureChange($event,row)" :min="1"></el-input-number>
						</template>
					</el-table-column>
					<el-table-column prop="life_num" label="当前人数" min-width="80"></el-table-column>
					<!-- <el-table-column prop="length" label="时长" min-width="60px"></el-table-column> -->
					<el-table-column prop="days" label="游玩日期" min-width="176">
						<template slot-scope="{row}">
							{{row.days}} <template v-if="row.start_at&&row.end_at">({{row.start_at}}-{{row.end_at}})</template>
						</template>
					</el-table-column>
					<el-table-column label="开放状态" min-width="80">
						<template slot-scope="{row}">
							<div class="btn-content">
								<el-tag v-if="row.is_open == 1" type="success">开放</el-tag>
								<el-tag v-else-if="row.is_open == 2" type="danger">关闭</el-tag>
							</div>
						</template>
					</el-table-column>
					<el-table-column width="70" label="状态">
						<template slot-scope="{row}">
							<div class="btn-content">
								<el-tag v-if="row.status == 1" type="success">未开始</el-tag>
								<el-tag v-if="row.status == 2" type="info">已开始</el-tag>
								<el-tag v-if="row.status == 3" type="danger">已结束</el-tag>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="created_at" width="148" label="创建时间"></el-table-column>
					<el-table-column width="470" label="操作" fixed="right">
						<template slot-scope="{row}">
							<div class="btn-content">
								<el-button class=" cur-p" type="primary" size="mini" v-if="row.status == 1"
									@click="saveStatus(row,2)">开始
								</el-button>
								<el-button class=" cur-p" type="primary" size="mini" v-if="row.status == 2"
									@click="saveStatus(row,3)">结束
								</el-button>
								<el-button class=" cur-p" type="primary" size="mini" @click="handEditShow(row)">信息
								</el-button>
								<el-button class=" cur-p" :type="!row.achievement_num?'info':'warning'" size="mini"
									@click="roomUserAddShow(row.id)">成就
								</el-button>
								<el-button class="cur-p" type="success" size="mini" @click="roomUserShow(row)">玩家
								</el-button>
								<el-button class="cur-p" type="primary" size="mini" @click="roomMessageList(row.id)">评论
								</el-button>
								<el-button class="cur-p" :type="row.is_open==1?'danger':'primary'" size="mini"
									@click="openRoom(row)">
									{{row.is_open==1?'关闭':'开放'}}
								</el-button>
								<el-button class="cur-p" type="success" size="mini" @click="showSpecia(row)">风采</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination-content">
					<el-pagination background :current-page.sync="searchForm.page" :page-size="searchForm.limit"
						@current-change="searchForm.page=$event" @size-change="handleSizeChange"
						layout="total, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		<div v-else-if="roomStep==1">
			<div class="park-form" :key="1">
				<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
					<el-form-item style="margin-top: auto;">
						<el-button class="fill-btn" size="mini" @click="roomStep=0">返回</el-button>
					</el-form-item>
					<!-- 文件类型 1 图片 2 视频 -->
					<el-form-item label="文件类型" prop="type">
						<el-select v-model="search.type" clearable placeholder="请选择文件类型">
							<el-option label="图片" value="1"></el-option>
							<el-option label="视频" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="margin-top: auto;margin-right: auto;">
						<div class="fill-btn" @click="getRoomSpecialList">查询</div>
					</el-form-item>
					
					<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
						<div class="fill-btn" @click="addShow">新增</div>
					</el-form-item>
				</el-form>
			</div>
			<div class="table-content">
				<div class="table-top">
					<div class="table-top-text">
						<!--已选择{{selectData.length}}项-->
					</div>
				</div>
				<el-table :key="1" :data="search.list" stripe v-loading="loading"
					cell-class-name="text-center" header-cell-class-name="header-row-class-name">
					<el-table-column prop="id" label="ID" min-width="60px"></el-table-column>
					<!-- <el-table-column prop="title" label="标题" min-width="80px"></el-table-column> -->
					<el-table-column label="文件类型" min-width="60px">
						<template slot-scope="{row}">
							{{row.type==1?'图片':'视频'}}
						</template>
					</el-table-column>
					<el-table-column min-width="150px" label="文件">
						<template slot-scope="{row}">
							<el-image v-if="row.type==1" style="width: 40px; height: 40px" :src="row.url" :preview-src-list="[row.url]">
							</el-image>
							<el-tooltip v-else class="item" effect="light" :content="row.url" placement="top-start">
								<span class="line-clamp-1">{{row.url}}</span>
							</el-tooltip>
						</template>
					</el-table-column>
					<el-table-column prop="created_at" min-width="150px" label="创建时间"></el-table-column>
					<el-table-column prop="created_at" width="200" label="操作">
						<template slot-scope="{row}">
							<div class="btn-content">
								<el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
									@click="editShow(row)"></el-button>
								<el-button class="el-icon-delete cur-p" type="primary" size="mini" @click="delSpecial(row)">
								</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination-content">
					<el-pagination background :current-page.sync="search.page" :page-size="search.limit"
						@current-change="search.page=$event" @size-change="handleSpeciaChange"
						layout="total, prev, pager, next, jumper" :total="search.total">
					</el-pagination>
				</div>
			</div>
		</div>
		<el-table
			v-else-if="roomStep==3"
		    v-loading="loading"
		    style="width: 100%">
		</el-table>
		<el-dialog title="房间创建" :visible.sync="showAdd">
			<div style="padding: 20px;">
				<el-form ref="formAdd" :rules="formRules" :model="addCourse" label-width="120px">
					<el-form-item label="门店" prop="shop_id" v-if="!userInfo.id">
						<el-select v-model="addCourse.shop_id" @change="getThemeLists(addCourse.shop_id)" filterable
							remote reserve-keyword placeholder="请选择门店" :remote-method="getStoreAll"
							:loading="selLoading">
							<el-option v-for="item in storeList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="主题" prop="theme_id">
						<el-select v-model="addCourse.theme_id" @change="getTimeList(addCourse.theme_id)" clearable
							placeholder="请选择">
							<el-option v-for="item in themeLists" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="提前进入分钟数" prop="before_time">
						<el-input v-model="addCourse.before_time"></el-input>
					</el-form-item>
					<el-form-item label="时间段" prop="value">
						<el-time-picker is-range v-model="addCourse.value" value-format="HH:mm" range-separator="至"
							start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
						</el-time-picker>
					</el-form-item>
					<el-form-item label="结束关闭分钟数" prop="after_time">
						<el-input v-model="addCourse.after_time"></el-input>
					</el-form-item>
					<el-form-item label="人数" prop="num">
						<el-input v-model="addCourse.num"></el-input>
					</el-form-item>
					<el-form-item label="时长(分钟)" prop="length">
						<el-input v-model="addCourse.length"></el-input>
					</el-form-item>
					<el-form-item label="房间备注">
						<el-input v-model="addCourse.remark" type="textarea" placeholder="房间备注"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" :loading="editing" @click="createCourse" style="margin: 10px auto">保存
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>

		<el-dialog title="房间编辑" :visible.sync="showEdit">
			<div style="padding: 20px;">
				<el-form ref="form" :model="editForm" :rules="formRules" label-width="120px">
					<el-form-item label="门店" v-if="!userInfo.id">
						<el-select v-model="editForm.shop_id" @change="getThemeLists(editForm.shop_id)" filterable
							remote reserve-keyword placeholder="请选择门店" :remote-method="getStoreAll"
							:loading="selLoading">
							<el-option v-for="item in storeList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<!-- {{editForm.theme_id}} -->
					<el-form-item label="主题" prop="theme_id">
						<el-select v-model="editForm.theme_id" @change="getTimeList(editForm.theme_id)" clearable
							placeholder="请选择">
							<el-option v-for="item in themeLists" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="提前进入分钟数" prop="before_time">
						<el-input :disabled="!editForm.status==1" v-model="editForm.before_time"></el-input>
					</el-form-item>
					<el-form-item label="时间段" prop="value">
						<el-time-picker :disabled="!editForm.status==1" is-range v-model="editForm.value" format="HH:mm"
							value-format="HH:mm" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
							placeholder="选择时间范围">
						</el-time-picker>
					</el-form-item>
					<el-form-item label="结束关闭分钟数" prop="after_time">
						<el-input :disabled="!editForm.status==1" v-model="editForm.after_time"></el-input>
					</el-form-item>
					<el-form-item label="人数" prop="num">
						<el-input v-model="editForm.num"></el-input>
					</el-form-item>

					<el-form-item label="时长(分钟)" prop="length">
						<el-input v-model="editForm.length"></el-input>
					</el-form-item>

					<el-form-item label="房间备注" prop="remark">
						<el-input v-model="editForm.remark" type="textarea" placeholder="房间备注"></el-input>
					</el-form-item>
					<el-form-item label="精彩回顾" prop="video_h">
						<el-upload class="avatar-uploader" :data="{is_editor:2}" action="/admin/uploadVideo" :show-file-list="false"
							:on-success="handleMP4sSuccess1">
							<video controls v-if="editForm.video_h" :src="editForm.video_h" class="avatar"></video>
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
					<!-- <el-form-item label="房间回顾" prop="content">
						<RichText v-model="editForm.content" placeholder="房间回顾"></RichText>
					</el-form-item> -->
					<el-form-item>
						<el-button type="primary" :loading="editing" @click="saveCourse" style="margin: 10px auto">保存
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>

		<!-- 房间用户 -->
		<el-dialog title="房间用户" :visible.sync="playUser">
			<div style="padding: 20px;">
				<div class="park-form">
					<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
						<el-form-item style="margin-top: auto;margin-right: auto;margin-left: 20px;">
							<div @click="handRoomUserShow()" class="fill-btn">新增</div>
						</el-form-item>
					</el-form>
				</div>
				<el-table :data="roomUser" stripe v-loading="MoneyLoading" cell-class-name="text-center"
					header-cell-class-name="header-row-class-name">
					<el-table-column prop="phone" label="用户" min-width="100px"></el-table-column>
					<el-table-column prop="nickname" label="昵称" min-width="120px"></el-table-column>
					<el-table-column prop="head" label="头像" min-width="60">
						<template slot-scope="{row}">
							<el-image style="width: 40px; height: 40px" :src="row.head" :preview-src-list="[row.head]">
							</el-image>
							<!-- <img :src="scope.row.head" min-width="80" height="70" /> -->
						</template>
					</el-table-column>
					<el-table-column prop="created_at" label="时间" min-width="120px"></el-table-column>
					<el-table-column width="260">
						<template slot-scope="{row}">
							<el-button class="cur-p" type="primary" size="mini" @click="hSAchieve(row)">发放成就</el-button>
							<el-button class="cur-p" type="warning" size="mini" @click="rUAShow(row)">用户成就
							</el-button>
							<el-button class="el-icon-delete cur-p" type="danger" size="mini" @click="roomUserDel(row)">
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination-content">
					<el-pagination background :current-page.sync="userRooms.page" :page-size="userRooms.limit"
						@current-change="userRooms.page=$event" layout="total, prev, pager, next"
						:total="userRooms.total">
					</el-pagination>
				</div>
			</div>
		</el-dialog>
		<el-dialog :title="`${rua.room_id}房间${rua.user_name}用户成就`" :visible.sync="rua.show">
			<div style="padding: 0 20px;">
				<el-table :data="rua.list" stripe v-loading="MoneyLoading" cell-class-name="text-center"
					header-cell-class-name="header-row-class-name">
					<el-table-column prop="get_achievement.title" label="成就" min-width="120"></el-table-column>
					<el-table-column prop="created_at" label="时间" min-width="120px"></el-table-column>
					<el-table-column label="操作" min-width="120px">
						<template slot-scope="{row}">
							<el-button class="el-icon-delete cur-p" type="danger" size="mini" @click="ruaCancel(row)">
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination-content">
					<el-pagination background :current-page.sync="rua.page" :page-size="rua.limit"
						@current-change="rua.page=$event" layout="total, prev, pager, next" :total="rua.total">
					</el-pagination>
				</div>
			</div>
		</el-dialog>
		<el-dialog :title="`${send.user_name}成就发放`" :visible.sync="send.show">
			<div style="padding: 20px;">
				<el-form ref="forms" :model="send" label-width="90px">
					<el-form-item label="成就" prop="achievement_arr"
						:rules="[{required: true,message: '请选择成就',trigger: ['blur', 'change']}]">
						<el-select v-model="send.achievement_arr" filterable multiple placeholder="请选择">
							<el-option v-for="item in roomsAchievementList" :key="item.achievement_id"
								:label="item.title" :value="item.achievement_id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" :loading="editing" @click="giveUserAchievement"
							style="margin: 10px auto">提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog title="用户添加" :visible.sync="showUserAdd">
			<div style="padding: 20px;">
				<el-form ref="form" :model="addUser" label-width="80px">
					<el-form-item label="用户账号">
						<el-input v-model="addUser.phone"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="roomUserAdd" style="margin: 10px auto">提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<!-- 房间成就 -->
		<el-dialog title="房间成就" :visible.sync="showUserAchievement">
			<div style="padding:0 20px;">
				<div class="park-form">
					<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
						<el-form-item label="成就发放方式" prop="type">
							<el-select v-model="achieveRooms.type" clearable placeholder="请选择成就发放方式">
								<el-option v-for="(name,id) in typeObj" :key="id" :label="name" :value="id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item style="margin-top: auto;margin-right: auto;">
							<div class="fill-btn" @click="roomAchievements">查询</div>
						</el-form-item>
						<el-form-item style="margin-top: auto;margin-right: auto;margin-left: 20px;">
							<div @click="showchievement()" class="fill-btn">新增</div>
						</el-form-item>
					</el-form>
				</div>
				<el-table :data="achievement" stripe v-loading="MoneyLoading" cell-class-name="text-center"
					header-cell-class-name="header-row-class-name">
					<el-table-column prop="title" label="成就名称" min-width="100px"></el-table-column>
					<el-table-column prop="images" label="成就图片" min-width="50">
						<!-- 图片的显示 -->
						<template slot-scope="{row}">
							<el-image style="width: 40px; height: 40px" :src="row.images"
								:preview-src-list="[row.images]">
							</el-image>
							<!-- <img :src="scope.row.images" min-width="80" height="70" style="width: 100%" /> -->
						</template>
					</el-table-column>
					<el-table-column prop="type" label="成就发放方式" min-width="90px">
						<template slot-scope="{row}">
							{{typeObj[row.type]}}
						</template>
					</el-table-column>
					<el-table-column prop="num" label="分数" min-width="90px"></el-table-column>
					<el-table-column prop="created_at" label="时间" min-width="120px"></el-table-column>
					<el-table-column width="100">
						<template slot-scope="{row}">
							<div class="btn-content">
								<el-button class="el-icon-delete cur-p" type="danger" size="mini"
									@click="roomAchievementDel(row)"></el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination-content">
					<el-pagination background :current-page.sync="achieveRooms.page" :page-size="achieveRooms.limit"
						@current-change="achieveRooms.page=$event" layout="total, prev, pager, next"
						:total="achieveRooms.total">
					</el-pagination>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="成就添加" :visible.sync="showAchievementAdd">
			<div style="padding: 20px;">
				<el-form ref="forms" :model="addAchievement" :rules="rules" label-width="90px">
					<el-form-item label="成就" prop="achievement_id">
						<el-select v-model="addAchievement.achievement_id" filterable multiple placeholder="请选择">
							<el-option v-for="item in achievementList" :key="item.id" :label="item.title"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="发放方式" prop="type">
						<el-select v-model="addAchievement.type" clearable placeholder="请选择成就发放方式">
							<el-option v-for="(name,id) in typeObj" :key="id" :label="name" :value="id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="成就积分" prop="num">
						<el-input type="number" v-model="addAchievement.num" placeholder="请输入成就积分" />
					</el-form-item>
					<el-form-item>
						<el-button type="primary" :loading="editing" @click="roomAchievementAdd"
							style="margin: 10px auto">提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog title="房间评论" :visible.sync="userMessage" width="70%">
			<div style="padding: 20px;">
				<div class="park-form">
					<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
						<el-form-item style="margin-top: auto;margin-right: auto;margin-left: 20px;">
							<div @click="handAddMShow()" class="fill-btn">新增</div>
						</el-form-item>
					</el-form>
				</div>
				<el-table :data="messageList" stripe v-loading="MoneyLoading" cell-class-name="text-center"
					header-cell-class-name="header-row-class-name">
					<el-table-column prop="phone" label="用户" min-width="120px"></el-table-column>
					<el-table-column prop="nickname" label="昵称" min-width="120px"></el-table-column>
					<el-table-column prop="contents" label="评论内容" min-width="140px"></el-table-column>
					<el-table-column prop="re_count" label="回复内容" min-width="140px"></el-table-column>
					<!--<el-table-column prop="head" label="头像" min-width="50%">-->
					<!--<template slot-scope="scope">-->
					<!--<img :src="scope.row.head" min-width="80" height="70"/>-->
					<!--</template>-->
					<!--</el-table-column>-->
					<el-table-column width="200 " label="状态">
						<template slot-scope="{row}">
							<div class="btn-content">
								<el-tag v-if="row.re_count" type="success">已回复</el-tag>
								<el-tag v-else type="info">未回复</el-tag>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="created_at" label="时间" min-width="120px"></el-table-column>
					<el-table-column width="200">
						<template slot-scope="{row}">
							<div class="btn-content">
								<el-button v-if="!row.re_count" class=" cur-p" type="primary" size="mini"
									@click="ptMessage(row)">回复</el-button>
								<el-button class=" cur-p" type="danger" size="mini" @click="delMessage(row)">删除
								</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>
		<el-dialog title="评论回复" :visible.sync="replayBox">
			<div style="padding: 20px;">
				<el-form ref="form" :model="replayMessage" label-width="120px">
					<el-form-item label="回复内容">
						<el-input v-model="replayMessage.contents"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="replyMessage" style="margin: 10px auto">提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog title="新增评论" :visible.sync="addMessage">
			<div style="padding: 20px;">
				<el-form ref="form" :model="ptAddMessage" label-width="120px">
					<el-form-item label="评论内容">
						<el-input v-model="ptAddMessage.contents"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="addNewMessage" style="margin: 10px auto">提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog :title="`${search.editId?'编辑':'创建'}房间风采`" :visible.sync="search.show">
			<div style="padding: 20px;">
				<el-form ref="formSpecial" :model="search.form" :rules="rulesSearch" label-width="120px">
					<el-form-item label="文件类型" prop="type">
						<el-select v-model="search.form.type" clearable placeholder="请选择文件类型">
							<el-option label="图片" value="1"></el-option>
							<el-option label="视频" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="上传文件" prop="type">
						<el-upload
						  class="upload-demo"
						  v-if="search.form.type==1"
						  action="/admin/upload" :file-list="search.fileList" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						  <el-button size="small" type="primary">点击上传</el-button>
						  <div slot="tip" class="el-upload__tip">只能上传{{search.form.type==2?'视频':'图片'}}文件，且不超过{{search.form.type==2?'512M':'50M'}}</div>
						</el-upload>
						<el-upload
						  class="upload-demo"
						  v-else-if="search.form.type==2"
						  action="/admin/uploadVideo" :file-list="search.fileList" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						  <el-button size="small" type="primary">点击上传</el-button>
						  <div slot="tip" class="el-upload__tip">只能上传{{search.form.type==2?'视频':'图片'}}文件，且不超过{{search.form.type==2?'512M':'50M'}}</div>
						</el-upload>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" :loading="editing" v-if="search.editId" @click="editSpecial" style="margin: 10px auto">提交</el-button>
						<el-button type="primary" :loading="editing" v-else @click="addSpecial" style="margin: 10px auto">提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		delToast,
		formVerify,
		saveToast
	} from './../../utlis/decorators'
	import RichText from './../../components/RichText'

	export default {
		name: "tenant_account",
		props: {
			parkId: {
				type: [Number, String],
				default: null
			},
		},
		components: {
			RichText
		},

		data() {
			return {
				replayBox: false,
				addMessage: false,
				replayMessage: {
					p_id: '',
					contents: '',
					room_id: ''
				},
				ptAddMessage: {
					contents: '',
					room_id: ''
				},
				userMessage: false,
				messageList: [],
				room_id: '',
				showAchievementAdd: false,
				showUserAdd: false,
				showUserAchievement: false,
				dialogFormVisible: false,
				loading: false,
				MoneyLoading: false,
				currentTop: 0,
				total: 0,
				showAdd: false,
				showEdit: false,
				showEditPsd: false,
				editing: false,
				playUser: false,
				phone: '',
				achievement: [],
				addUser: {
					phone: '',
					room_id: ''
				},
				addAchievement: {
					achievement_id: '',
					room_id: '',
					user_id: '',
					type: '',
					num: '',
				},
				roomUseList: {
					id: '',
					nickname: '',
					phone: ''
				},
				roomUser: [],
				roomAchievement: [],
				searchForm: {
					page: 1,
					limit: 10,
					username: '',
					date: '',
					days: '',
					is_open: '',
					status: '',
				},
				form: {
					nickname: '',
					images: ''
				},
				editForm: {
					shop_id: '',
					value: [],
					theme_id: '',
					status: '',
					num: '',
					length: '',
					remark: '',
					video_h: '',
					before_time: '',
					after_time: '',
				},
				currentId: '',
				tableData: [],

				addCourse: {
					shop_id: '',
					theme_id: '',
					value: '',
					num: '',
					remark: '',
					length: '',
					before_time: '',
					after_time: '',
				},
				teacherAll: [],
				achievementList: [],
				user_type: '',
				themeList: '',
				timeList: [],

				achieveRooms: {
					id: '',
					page: 1, //页数
					limit: 8, //每页数
					total: '',
					type: '', //成就发放方式 1 进房间发放 2 复盘后发放 3 手动发放
				},
				userRooms: {
					id: '',
					page: 1, //页数
					limit: 8, //每页数
					total: '',
				},
				typeObj: {
					1: '进房间发放',
					2: '复盘后发放',
					3: '手动发放'
				},

				rua: {
					user_id: '',
					list: [],
					page: 1, //页数
					limit: 8, //每页数
					total: '',
					show: false,
				},
				send: {
					user_id: '',
					show: false,
					user_name: '',
					achievement_arr: [],
				},
				selLoading: false,
				storeList: [],
				themeLists: [],
				roomsAchievementList: [],
				
				roomStep:3,
				search:{
					list:[],
					fileList:[],
					total:0,
					page: 1, //页数
					limit: 8, //每页数
					room_id: '', //房间id
					type: '', //文件类型 1 图片 2 视频
					show:false,
					editId:'',
					form:{
						type:'',
						url:'',
					}
				},
				allThemeObj:{},
				timer:null,
				roomObj:{},
				
				themeSearch:{
					page: 1,
					limit: 6,
					store_id: '',
				},
				userInfo:{}
			}
		},
		created() {
			this.roomObj = {};
			this.getStoreTimeInfo();
			this.getThemeList(1);
			//成就列表
			this.achievementLists();
			this.getStoreAll();
			this.user_type = window.sessionStorage.getItem('type')
		},
		computed: {
			formRules() {
				return {
					theme_id: [{
						required: true,
						message: '请选择主题',
						trigger: ['blur', 'change']
					}],
					value: [{
						required: true,
						message: '请选择时间',
						trigger: ['blur', 'change']
					}],
					num: [{
						required: true,
						message: '请输入人数',
						trigger: ['blur', 'change']
					}],
					length: [{
						required: true,
						message: '请输入时长',
						trigger: ['blur', 'change']
					}],
					before_time: [{
						required: true,
						message: '请输入提前进入分钟数',
						trigger: ['blur', 'change']
					}, {
						validator: this.$validate.checkMaxTime,
						trigger: ['blur', 'change']
					}],
					after_time: [{
						required: true,
						message: '请输入结束关闭分钟数',
						trigger: ['blur', 'change']
					}, {
						validator: this.$validate.checkMaxTime,
						trigger: ['blur', 'change']
					}],
				}
			},
			rules() {
				return {
					achievement_id: [{
						required: true,
						message: `请选择成就`,
						trigger: ['blur', 'change']
					}],
					type: [{
						required: true,
						message: `请选择成就发放方式`,
						trigger: ['blur', 'change']
					}],
					num: [{
						required: true,
						message: `成就积分不能为空`,
						trigger: ['blur', 'change']
					}, {
						validator: this.$validate.checkFuNum,
						trigger: ['blur', 'change']
					}],

				}
			},
			rulesSearch() {
				return {
					type: [{
						required: true,
						message: '请选择文件类型',
						trigger: ['blur', 'change']
					}],
					url: [{
						required: true,
						message: '请上传文件',
						trigger: ['blur', 'change']
					}],
				}
			}
		},
		watch: {
			'searchForm.page'() {
				this.roomList();
			},
			'achieveRooms.page'() {
				this.roomAchievements();
			},
			'userRooms.page'() {
				this.roomAchievements();
			},
			'rua.page'() {
				this.getUserAchievementList();
			},
			'search.page'() {
				this.getRoomSpecialList();
			},
		},
		beforeDestroy(){
			for(let i in this.roomObj){
				this.renewRoomOne(this.roomObj[i],'num')
			}
			this.roomObj = {}
		},
		methods: {
			//获取用户信息
			async getStoreTimeInfo() {
			    let [e, data] = await this.$api.getStoreTimeInfoApi();
			    if (e) return;
			    if (data.code === 200) {
			       this.userInfo = data.data;
				   //房间主题
				   if (this.userInfo.id) {
				   	this.themeSearch.store_id = this.userInfo.id;
				   	this.getThemeLists(this.userInfo.id)
				   }
			    }
			},
			//点击切换
			handleClick(el){
				// this.searchForm.themes_is = el;
				this.searchForm.page = 1;
				this.roomList();
			},
			//修改数量
			sureChange(event,row){
				this.$set(this.roomObj,row.id,row)
			},
			//修改房间单个字段值
			async renewRoomOne(row,key){
				let [e, data] = await this.$api.renewRoomOneApi({
					id:row.id,//房间id
					field_key:key,//修改字段 num
					field_value:row[key],//修改值
				});
				if (e) return;
				if (data.code === 200) {
					
				}
			},
			//修改判断
			panDunIs(event){
				if(event.target.className != 'el-input-number__decrease'&&event.target.className != 'el-input-number__decrease'){
					for(let i in this.roomObj){
						this.renewRoomOne(this.roomObj[i],'num');
					}
					this.roomObj = {}
				}
			},
			//获取店铺
			async getStoreAll(name) {
				this.selLoading = true
				let [e, data] = await this.$api.searchStoreListApi({
					page: 1,
					limit: 50,
					name
				});
				this.selLoading = false
				if (e) return;
				if (data.code === 200) {
					this.storeList = data.data.data;
				}
			},
			//获取主题
			async getThemeList(type) {
				if(this.allThemeObj[this.themeSearch.page]&&this.allThemeObj[this.themeSearch.page].length){
					this.searchForm.themes_is = this.allThemeObj[this.themeSearch.page][0].themes_id;
					this.searchForm.page = 1;
					this.roomStep = 0;
					this.editing = false;
					this.roomList();
					return
				}
				this.loading = true;
				let [e, data] = await this.$api.getUniqueStoreThemeApi(this.themeSearch);
				if (e) return;
				if (data.code === 200) {
					if(this.themeSearch.page==1){
						this.$set(this.allThemeObj,this.themeSearch.page,[{themes_title:'全部',themes_id:''}].concat(data.data.data))
					}else{
						this.$set(this.allThemeObj,this.themeSearch.page,data.data.data)
					}
					this.editing = false
					if(this.allThemeObj[this.themeSearch.page].length){
						this.searchForm.themes_is = this.allThemeObj[this.themeSearch.page][0].themes_id;
						this.searchForm.page = 1;
						this.roomStep = 0;
						this.roomList();
					}
				}
			},
			//下一步
			nextTo(index){
				if(this.editing||this.themeSearch.page==0){
					return
				}
				this.editing = true;
				this.$set(this.themeSearch,'page',this.themeSearch.page + index)
				this.getThemeList();
			},
			//获取主题
			async getThemeLists(store_id) {
				let [e, data] = await this.$api.themeLists({
					store_id
				});
				if (e) return;
				if (data.code === 200) {
					this.themeLists = data.data;
					this.addCourse.theme_id = '';
					this.editForm.theme_id = '';
					if (this.editForm.oldTheme_id) {
						let obj = this.themeLists.find(item => {
							return item.id == this.editForm.oldTheme_id
						})
						if (obj.id) {
							this.addCourse.theme_id = Number(obj.id);
							this.editForm.theme_id = Number(obj.id);
						}
					}
					if (!this.themeList.length) {
						this.themeList = data.data;
					}
				}
			},
			//获取预定信息
			async getTimeList(id) {
				let [e, data] = await this.$api.getTimeList({
					theme_id: id
				});
				if (e) return;
				if (data.code === 200) {
					this.addCourse.reserve_id = '';
					this.editForm.reserve_id = '';

					this.timeList = data.data;
				}
			},
			handleMP4sSuccess1(res, file) {
				this.editForm.video_h = res.url;
				this.$forceUpdate();
			},
			//获取店铺成就
			async achievementLists() {
				let [e, data] = await this.$api.achievementLists();
				if (e) return;

				if (data.code === 200) {
					this.achievementList = data.data;
				}
			},
			//添加成就
			async showchievement() {
				this.showAchievementAdd = true;
				let [e, data] = await this.$api.roomUser({
					id: this.room_id
				});
				if (e) return;
				if (data.code === 200) {
					this.roomUseList = data.data;
				}
			},
			//状态修改
			@saveToast()
			async saveStatus({
				id
			}, status) {
				let [e, data] = await this.$api.roomSaveStatus({
					id,
					status
				});
				if (e) return;
				if (data.code === 200) {
					this.loading = true;
					setTimeout(()=>{
						this.roomList();
					},1000)
					this.$message.success('成功')
				}
			},
			handShowAdd() {
				try {
					this.showAdd = true;
					this.$refs['formAdd'].resetFields()
					for (let i in this.addCourse) {
						this.addCourse[i] = '';
					}
					this.addCourse.shop_id = this.userInfo.id;

				} catch {}

			},
			//显示房间成就
			roomUserAddShow(id) {
				this.room_id = id;
				this.achieveRooms.id = id;
				this.addAchievement = {};
				this.achieveRooms.page = 1;
				this.roomAchievements();
			},
			//显示房间成就
			async roomAchievements() {
				this.MoneyLoading = true;
				let [e, data] = await this.$api.roomAchievement(this.achieveRooms);
				this.MoneyLoading = false;
				if (e) return;
				if (data.code === 200) {
					this.achievement = data.data.data;
					this.achieveRooms.total = data.data.total;
					this.showUserAchievement = true;
				}
			},
			//添加成就
			@formVerify('forms')
			async roomAchievementAdd() {
				this.editing = true;
				this.addAchievement.room_id = this.room_id;
				let [e, data] = await this.$api.roomAchievementAdd(this.addAchievement);
				if (e) {
					this.editing = false;
					return;
				}
				if (data.code === 200) {
					this.roomUserAddShow(this.addAchievement.room_id);
					this.$message.success('添加成功')
					this.showAchievementAdd = false;
					this.editing = false;
					this.roomList();
				} else {
					this.editing = false;
				}
			},
			//删除成就
			@delToast()
			async roomAchievementDel(row) {
				let [e, data] = await this.$api.roomAchievementDel({
					id: row.id
				});
				if (e) return;
				if (data.code === 200) {
					this.roomUserAddShow(row.room_id);
					this.$message.success('删除成功')
					this.roomList();
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			handRoomUserShow() {
				this.addUser.phone = '';
				this.showUserAdd = true;
			},
			//添加评论
			handAddMShow() {
				this.ptAddMessage.contents = '';
				this.addMessage = true;
			},
			//添加成员
			async roomUserAdd() {
				let [e, data] = await this.$api.roomUserAdd(this.addUser);
				if (e) return;
				if (data.code === 200) {
					this.roomUserList(this.addUser.room_id);
					this.$message.success('添加成功')
					this.roomList();
					this.showUserAdd = false;
				}
			},
			async handEditShow(row) {

				// let [e, data] = await this.$api.getTimeList({
				// 	theme_id: row.theme_id
				// });
				// this.editForm = JSON.parse(JSON.stringify(row));
				// this.editForm.value = [this.editForm.start_at, this.editForm.end_at];
				// if (data.code === 200) {

				// 	this.timeList = data.data;

				// }
				let [e, data] = await this.$api.getRoomInfoApi({
					id: row.id
				});
				if (e) return;
				this.editForm = JSON.parse(JSON.stringify(row));
				this.$set(this.editForm, 'value', [row.start_at, row.end_at]);
				this.editForm.content = data.data.content;
				this.$set(this.editForm, 'video_h', data.data.video_h);
				this.$set(this.editForm, 'oldTheme_id', row.theme_id);
				this.$set(this.editForm, 'shop_id', this.userInfo.id || row.shop_id);
				if (!this.userInfo.id && row.shop_id) {
					this.getThemeLists(row.shop_id)
				}
				this.showEdit = true;
			},
			//获取列表
			async roomList() {				
				this.loading = true;
				let [e, data] = await this.$api.roomList(this.searchForm);
				this.loading = false;
				if (e) return;
				if (data.code === 200) {
					this.tableData = data.data.data;
					this.total = data.data.total;
				}
			},
			//页面数量改变
			handleSizeChange(val) {
				this.searchForm.page = 1;
				this.searchForm.limit = val;
			},	
			handleSelectionChange(val) {
				this.selectData = val;
			},
			//显示用户
			roomUserShow({
				id,
				number
			}) {
				this.userRooms.id = id;
				this.addUser.room_id = id;
				this.$set(this.rua, 'room_id', number)
				this.userRooms.page = 1;
				this.roomUserList();
			},
			//用户列表
			async roomUserList() {
				this.MoneyLoading = true;
				let [e, data] = await this.$api.roomUserList(this.userRooms);
				this.MoneyLoading = false;
				if (e) return;
				if (data.code === 200) {
					this.roomUser = data.data.data;
					this.userRooms.total = data.data.total;
					this.playUser = true;
				}
			},
			//用户评论列表
			async roomMessageList(id) {
				let [e, data] = await this.$api.roomMessage({
					id: id
				});
				if (e) return;
				this.room_id = id;
				if (data.code === 200) {
					this.messageList = data.data.data;
					this.userMessage = true;
				}
			},
			//评论
			async ptMessage(row) {
				this.replayBox = true;
				this.replayMessage.p_id = row.id;
				this.replayMessage.contents = row.store_message ? row.store_message.contents : '';
				this.replayMessage.room_id = row.room_id;
			},
			//用户评论回复
			async replyMessage() {
				let [e, data] = await this.$api.replyMessage(this.replayMessage);
				if (e) return
				if (data.code === 200) {
					this.$message.success('成功');
					this.replayBox = false;
					this.roomMessageList(this.replayMessage.room_id);
				}
			},
			//评论
			async addNewMessage() {
				this.ptAddMessage.room_id = this.room_id
				let [e, data] = await this.$api.addptMessage(this.ptAddMessage);
				if (e) return
				if (data.code === 200) {
					this.$message.success('成功');
					this.addMessage = false;
					this.roomMessageList(this.room_id);
				}
			},
			@delToast()
			async roomUserDel(row) {
				let [e, data] = await this.$api.roomUserDel({
					id: row.id
				});
				if (e) return;
				if (data.code === 200) {
					this.roomUserList(row.room_id);
					this.$message.success('删除成功');
					this.roomList();
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			@delToast()
			async delMessage(row) {
				let [e, data] = await this.$api.delMessage({
					id: row.id
				});
				if (e) return;
				if (data.code === 200) {
					this.roomMessageList(row.room_id);
					this.$message.success('删除成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			@delToast()
			async teacherDel({
				id
			}) {
				let [e, data] = await this.$api.roomDel({
					id
				});
				if (e) return;
				if (data.code === 200) {
					this.roomList();
					this.$message.success('删除成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},

			//添加房间
			@formVerify('formAdd')
			async createCourse() {
				this.editing = true;
				this.addCourse.shop_id = this.userInfo.id;
				let [e, data] = await this.$api.roomCreate(this.addCourse);
				this.editing = false;
				if (e) return;
				if (data.code === 200) {
					this.roomList();
					this.showAdd = false;
					this.$message.success('添加成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			//编辑
			@formVerify('form')
			async saveCourse() {
				this.editing = true;
				let [e, data] = await this.$api.roomSave(this.editForm);
				this.editing = false;
				if (e) return;
				if (data.code === 200) {
					this.showEdit = false;
					this.roomList();
					this.$message.success('保存成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			//显示房间用户成就
			rUAShow({
				user_id,
				phone
			}) {
				this.rua.user_id = user_id;
				this.rua.page = 1;
				this.$set(this.rua, 'user_name', phone)
				// this.rus.user_name = nickname;
				this.getUserAchievementList();
			},

			//获取房间用户成就
			async getUserAchievementList() {
				this.MoneyLoading = true;
				let [e, data] = await this.$api.getUserAchievementListApi({
					page: this.rua.page,
					limit: this.rua.limit,
					room_id: this.addUser.room_id,
					user_id: this.rua.user_id,
				});
				this.MoneyLoading = false;
				if (e) return;
				if (data.code === 200) {
					this.rua.list = data.data.data;
					this.rua.total = data.data.total;
					this.rua.show = true;
				}
			},
			//显示用户发放成就
			async hSAchieve({
				user_id,
				phone
			}) {
				this.send.user_id = user_id;
				this.$set(this.send, 'user_name', phone)
				let [e, data] = await this.$api.getRoomAchievementAllApi({
					room_id: this.addUser.room_id,
				});
				try {
					this.send.show = true;
					this.$refs['forms'].resetFields()
					this.send.achievement_arr = [];
				} catch {}
				if (e) return;
				if (data.code === 200) {
					this.roomsAchievementList = data.data;
				}
			},
			//发放成就
			@formVerify('forms')
			async giveUserAchievement() {
				this.editing = true;
				let [e, data] = await this.$api.giveUserAchievementApi({
					achievement_arr: this.send.achievement_arr,
					room_id: this.addUser.room_id,
					user_id: this.send.user_id,
				});
				this.editing = false;
				if (e) return;
				if (data.code === 200) {
					this.send.show = false;
					this.$message.success('发放成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			//取消用户成就
			@delToast('此操作将取消该用户成就, 是否继续?')
			async ruaCancel(row) {
				let [e, data] = await this.$api.cancelUserAchievementApi({
					room_id: this.addUser.room_id,
					user_id: this.rua.user_id,
					achievement_id: row.achievement_id
				});
				if (e) return;
				if (data.code === 200) {
					this.getUserAchievementList();
					this.$message.success('取消成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			//取消用户成就
			@delToast('此操作将开关房间, 是否继续?')
			async openRoom(row) {
				let [e, data] = await this.$api.openRoomApi({
					id: row.id,
					is_open: row.is_open == 1 ? 2 : 1, //1 开放 2 关闭
				});
				if (e) return;
				if (data.code === 200) {
					this.roomList();
					this.$message.success('操作成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			//页面数量改变
			handleSpeciaChange(val) {
				this.search.page = 1;
				this.search.limit = val;
			},
			//显示房间风采
			showSpecia(row){
				this.search.room_id = row.id;
				this.search.page = 1;
				this.roomStep = 1;
				this.getRoomSpecialList();
			},
			//获取房间风采列表
			async getRoomSpecialList(){
				this.loading = true;
				let [e, data] = await this.$api.getRoomSpecialListApi({
					page: this.search.page,
					limit: this.search.limit,
					room_id: this.search.room_id,
					type: this.search.type,
				});
				this.loading = false;
				if (e) return;
				if (data.code === 200) {
					this.$set(this.search,'list',data.data.data);
					this.search.total = data.data.total;
				}
			},
			//添加显示
			addShow(){
				this.search.editId = '';
				try {
					this.search.show = true;
					this.$refs['formSpecial'].resetFields()
					for (let i in this.search.form) {
						this.search.form[i] = '';
					}	
					this.search.fileList = [];
				} catch {}
			},
			//编辑显示
			editShow({id,type,url}){
				this.search.editId = id;
				this.search.form.type = String(type);	
				this.search.form.url = url;	
				this.search.fileList = [{name:url,url}];
				this.search.show = true;
			},
			//编辑显示
			@delToast('此操作将删除该房间风格, 是否继续?')
			async delSpecial({id}){
				let [e, data] = await this.$api.delRoomSpecialApi({
					id
				});
				if (e) return;
				if (data.code === 200) {
					this.getRoomSpecialList();
					this.$message.success('操作成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			//发放成就
			@formVerify('formSpecial')
			async addSpecial() {
				this.editing = true;
				let [e, data] = await this.$api.addRoomSpecialApi({
					...this.search.form,
					room_id:this.search.room_id
				});
				this.editing = false;
				if (e) return;
				if (data.code === 200) {
					this.search.show = false;
					this.getRoomSpecialList()
					this.$message.success('发放成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			//发放成就
			@formVerify('formSpecial')
			async editSpecial() {
				this.editing = true;
				let [e, data] = await this.$api.editRoomSpecialApi({
					...this.search.form,
					room_id:this.search.room_id,
					id:this.search.editId
				});
				this.editing = false;
				if (e) return;
				if (data.code === 200) {
					this.search.show = false;
					this.getRoomSpecialList()
					this.$message.success('发放成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			//上传文件成功
			handleAvatarSuccess(res, file) {
				if(this.search.fileList.length){
					this.search.fileList.splice(0,1,{name:res.url,url:res.url});
				}
				this.search.form.url = res.url;
			},
			//上传文件之前
			beforeAvatarUpload(file) {
				const isJPG = file.type&&file.type.indexOf('image/')>-1;				
				const isVideo = file.type&&file.type.indexOf('video/')>-1;				
				const isLtM = file.size / 1024 / 1024 < 50;				
				const isLtV = file.size / 1024 / 1024 < 512;			
				if(this.search.form.type==2){
					if (!isVideo) {
						this.$message.error('请上传视频!')
					   return false;
					}
					if (!isLtV) {
						this.$message.error('上传视频大小不能超过512MB!')
					   return false;
					}
				}else{
					if (!isJPG) {
						this.$message.error('请上传图片!')
					    return false;
					}
					if (!isLtM) {
						this.$message.error('上传图片大小不能超过50MB!')
					   return false;
					}
				}
				return true;
			},
		},
	}
</script>
<style scoped lang="less">
	.page-content {
		width: calc(~'100% - 60px');
		margin: 26px 30px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 4px;
		padding: 20px 30px;

		.park-form {
			border-bottom: 1px solid #efefef;
		}

		.table-content {
			padding-top: 20px;

			.table-top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.table-top-text {
					font-size: 16px;
					font-weight: 400;
					color: rgba(35, 39, 52, 1);
				}

				.table-top-btn {}
			}
		}
	}

	.btn-content {
		display: flex;
		justify-content: center;
		align-items: center;

		.iconfont {
			font-size: 20px;
			color: rgb(190, 199, 213);
			margin: 0 10px;
			cursor: pointer;
		}

		.icon-shanchu {
			font-size: 24px;
		}
	}

	.box-shadow {
		width: calc(~'100% + 40px');
		height: 50px;
		position: absolute;
		top: 0;
		left: -20px;
		z-index: 10;
		box-shadow: 0 0 4px #b4b4b4;
	}

	/deep/.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
	.next{
		display: inline-block;
		width: 37px;
		height: 37px;
	}
</style>
